import React from 'react';

import Layout from '../components/Layout';
import {ImageCard, MicroCard} from '../components/Card';


import GraphiteLogo from '../assets/images/graphitegtc-logo-gr.svg';
import AdvancedAnalyticsImage from '../assets/images/icon-benefits-advancedanalytics.svg';
import CloudEnablementImage from '../assets/images/icon-benefits-cloudenablement.svg';
import ConcurrencyControlImage from '../assets/images/icon-benefits-concurrencycontrol.svg';
import FutureProofImage from '../assets/images/icon-benefits-futureproof.svg';
import IntegrationImage from '../assets/images/icon-benefits-integration.svg';
import MobilityImage from '../assets/images/icon-benefits-mobility.svg';
import ResponsiveImage from '../assets/images/icon-benefits-responsiveui.svg';
import SQLInjectionImage from '../assets/images/icon-benefits-sqlinjection.svg';
import MultiImage from '../assets/images/icon-benefits-multilanguage.svg';
import SecurityImage from '../assets/images/icon-benefits-security.svg';
import StabilityImage from '../assets/images/icon-benefits-stability.svg';
import ScalabilityImage from '../assets/images/icon-scalability.svg';

import LowCodeNoCodeImage from '../assets/images/icon-flow.svg';
import RightImage from '../assets/images/icon-right.svg';

import PublicSectorImage from '../assets/images/icon-publicsector.svg';
import ModernizeImage from '../assets/images/icon-modernize.svg';
import CloudImage from '../assets/images/icon-cloudenable.svg';
import TalentImage from '../assets/images/icon-talent.svg';
import TransformImage from '../assets/images/icon-transform.svg';
import SolutionsImage from '../assets/images/icon-inventory.svg';

const herobg = '#1c2841', herofcolor='#efefef';
const CodePage = () => (
  <Layout>
      <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>
                        <h3 >Solutions using Low-code / No-Code</h3>
                        <h1 style={{letterSpacing:'.1em'}}>
                            Simplify complexities and realize faster time to business value.
                        </h1>
                </header>
        </div>
    
        
        <div className="inner row" >
            <div className='col-9 col-12-medium' style={{margin:'.25em auto'}}>
                <span className="image left">
                    <img src={LowCodeNoCodeImage} alt="Low Code/No Code" style={{width:'120px'}} />
                </span>
            
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    Today’s users demand applications that offer instant intelligence and collaboration, 
                    whenever they need it, on whichever device they choose. We help enterprises
                    build solutions for their business problems using low-code / no-code development 
                    environment. Our solutions achieve faster time to market, true agility, responsive 
                    interfaces and are built for the cloud.
                
                </p> 
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>
                    Our development environment
                </p>    
                <div className="inner row" style={{display:'flex', flexWrap:'wrap',margin:'.25em auto'}}>
                    <MicroCard className="col-6 col-12-small" cardImage={RightImage} cardTitle="Object Oriented Technology" />
                    <MicroCard className="col-6 col-12-small" cardImage={RightImage} cardTitle="Service oriented architecture" />
                    <MicroCard className="col-6 col-12-small" cardImage={RightImage} cardTitle="MVC Design pattern" />
                    <MicroCard className="col-6 col-12-small" cardImage={RightImage} cardTitle="N-tier Architecture" />
                    <MicroCard className="col-6 col-12-small" cardImage={RightImage} cardTitle="100% Visual Development" />
                    <MicroCard className="col-6 col-12-small" cardImage={RightImage} cardTitle="Asynch & Batch Processing" />
                    <MicroCard className="col-6 col-12-small" cardImage={RightImage} cardTitle="Drag & Drop UI" />
                    <MicroCard className="col-6 col-12-small" cardImage={RightImage} cardTitle="Flowchart based instructions" />
                    <MicroCard className="col-6 col-12-small" cardImage={RightImage} cardTitle="Entity Framework (ORM)" />
                    
                </div>
                
            
                
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>
                    Our Solution Benefits
                </p>
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    We build well architected modern low-code / no-code solutions. Our solutions have inbuilt compliance 
                    for Sarbanes–Oxley (SOX) Act and the Health Insurance Portability and Accountability Act (HIPAA).
                    We generate a complete normalized relational database based on the Entity model designed while development
                    with the help of Entity Framework (ORM).
                    <br/><br />
                    Our low-code / no-code solution's standard benefits are
                </p>

                <div className="inner row" style={{display:'flex', flexWrap:'wrap', margin:'.25em auto'}}>
                    
                    <MicroCard className="col-6 col-12-small" cardImage={AdvancedAnalyticsImage} cardTitle="Advanced Analytics" />
                    <MicroCard className="col-6 col-12-small" cardImage={CloudEnablementImage} cardTitle="Cloud Enablement" />
                    <MicroCard className="col-6 col-12-small" cardImage={ConcurrencyControlImage} cardTitle="Concurrency Control" />
                    <MicroCard className="col-6 col-12-small" cardImage={FutureProofImage} cardTitle="Future Proof" />
                    <MicroCard className="col-6 col-12-small" cardImage={IntegrationImage} cardTitle="Web Services Integration" />
                    <MicroCard className="col-6 col-12-small" cardImage={MobilityImage} cardTitle="Mobility" />
                    <MicroCard className="col-6 col-12-small" cardImage={ResponsiveImage} cardTitle="Responsive UI" />
                    <MicroCard className="col-6 col-12-small" cardImage={SQLInjectionImage} cardTitle="SQL Injection Prevention" />
                    <MicroCard className="col-6 col-12-small" cardImage={MultiImage} cardTitle="Multi Language & Currency" />
                    <MicroCard className="col-6 col-12-small" cardImage={SecurityImage} cardTitle="Security & Audit" />
                    <MicroCard className="col-6 col-12-small" cardImage={StabilityImage} cardTitle="Stability" />
                    <MicroCard className="col-6 col-12-small" cardImage={ScalabilityImage} cardTitle="Scalability" />
                    
                </div>
            </div>
        
            <div className='col-3 col-6-medium col-12-small' >
                <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em'}}>Explore</p>
                <MicroCard className='col-12 col-6-medium' 
                            cardTitle="Transformation" cardImage={TransformImage} 
                            cardLink='/transform-your-business'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Solutions on the cloud" cardImage={SolutionsImage} 
                        cardLink='/business-solutions-on-the-cloud'/>
                <MicroCard className='col-12 col-6-medium' 
                    cardTitle="Public Sector Services" cardImage={PublicSectorImage} 
                    cardLink='/public-sector-services'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Talent Services" cardImage={TalentImage} 
                        cardLink='/talent-services'/>
                <MicroCard className='col-12 col-6-medium' 
                    cardTitle="Modernize" cardImage={ModernizeImage} 
                    cardLink='/modernize-solutions'/>
                <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Enable the Cloud" cardImage={CloudImage} 
                        cardLink='/enable-the-cloud'/>
                
                <p style={{fontWeight:400, fontSize:'90%', margin:'1em 0 .25em'}}>Technology Partners</p>
                <ImageCard className='col-12 col-6-medium' cardImage={GraphiteLogo} height='20px'/>
        </div>
        </div>
    </div>
  </Layout>
);

export default CodePage;
